<template>
  <v-container class="py-12" align-center fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6" lg="5" xl="4">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  watch: {
    ["$route"](to, from) {
      if (Object.keys(from.query).length > 0) {
        // to prevent an infinite loop
        this.$router.replace({ query: from.query }).catch(() => {
          //ignore
        });
      }
    },
  },
};
</script>

<style>
</style>